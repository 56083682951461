import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useShare from '../../Services/Hooks/useShare';
import { settingsActions } from '../../Services/Reducers/setting-slice';

const Share = (props) => {
  const [shareService, setShareService] = useState(null);
  const [shareLink] = useShare(shareService, 'https://www.enableds.com', 'The Best Mobile Templates');

  const dispatch = useDispatch();
  const shareSelector = useSelector((state) => state.menuReducer.shareMenu);

  //ADD WEBSHARE API
  //ADD WEBSHARE API

  useEffect(() => {
    if (shareService !== null) {
      window.open(shareLink, '_blank');
    }
    setShareService(null);
  }, [shareLink, shareService]);

  return (
    <Offcanvas show={shareSelector} onHide={() => dispatch(settingsActions.CLOSE_MENU())} placement="bottom" className="mx-3 mb-3 rounded-m">
      <div className="content mt-3 mb-0">
        <div className="d-flex">
          <div className="align-self-center me-auto">
            <h1 className="mb-n2">Share the Love</h1>
            <p className="mb-0 color-highlight font-11">Just tap the Social Icon. We'll add the link</p>
          </div>
          <div className="align-self-center ms-auto">
            <button onClick={() => props.setToggleShare(false)} className="icon icon-m me-n3">
              <i className="fa fa-times color-red-dark"></i>
            </button>
          </div>
        </div>
        <div className="divider mb-0 mt-3"></div>
        <ListGroup className="list-group list-custom-small mx-0">
          <ListGroupItem action onClick={(e) => setShareService('facebook')}>
            <i className="font-18 fab fa-facebook-square color-facebook ms-n1"></i>
            <span className="font-13">Facebook</span>
            <i className="fa fa-angle-right"></i>
          </ListGroupItem>
          <ListGroupItem action onClick={(e) => setShareService('twitter')}>
            <i className="font-18 fab fa-twitter-square color-twitter ms-n1"></i>
            <span className="font-13">Twitter</span>
            <i className="fa fa-angle-right"></i>
          </ListGroupItem>
          <ListGroupItem action onClick={(e) => setShareService('linkedin')}>
            <i className="font-18 fab fa-linkedin color-linkedin ms-n1"></i>
            <span className="font-13">LinkedIn</span>
            <i className="fa fa-angle-right"></i>
          </ListGroupItem>
          <ListGroupItem action onClick={(e) => setShareService('whatsapp')}>
            <i className="font-18 fab fa-whatsapp-square color-whatsapp ms-n1"></i>
            <span className="font-13">WhatsApp</span>
            <i className="fa fa-angle-right"></i>
          </ListGroupItem>
          <ListGroupItem action className="border-0" onClick={(e) => setShareService('email')}>
            <i className="font-18 fa fa-envelope-square color-mail ms-n1"></i>
            <span className="font-13">Email</span>
            <i className="fa fa-angle-right"></i>
          </ListGroupItem>
        </ListGroup>
      </div>
    </Offcanvas>
  );
};

export default Share;
