import React, { useState } from 'react';

// Content Div
export const Content = (props) => <div className={`content ${props.className}`}>{props.children}</div>;
Content.defaultProps = { className: '' };

// Card and Content Pair Div
export const CardContent = (props) => (
  <div className={`card card-style ${props.bg}`}>
    <div className={`content ${props.className}`}>{props.children}</div>
  </div>
);
CardContent.defaultProps = { className: 'mb-0', bg: '' };

// Card and Content Pair Div
export const CardContentFull = (props) => (
  <div className={`card card-style mx-0 rounded-0 ${props.bg}`}>
    <div className={`content ${props.className}`}>{props.children}</div>
  </div>
);
CardContentFull.defaultProps = { className: 'mb-0', bg: '' };

// Card Style Div
export const CardStyle = (props) => {
  const [winHeight, setWinHeight] = useState(props.height === 'cover' ? (window.matchMedia('(display-mode: fullscreen)').matches ? window.outerHeight : window.innerHeight) : props.height);
  function debounce(data) {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(data, 100, event);
    };
  }
  window.addEventListener(
    'resize',
    debounce(function (e) {
      if (props.height === 'cover') {
        if (window.matchMedia('(display-mode: fullscreen)').matches) {
          setWinHeight(window.outerHeight);
        }
        if (!window.matchMedia('(display-mode: fullscreen)').matches) {
          setWinHeight(window.innerHeight);
        }
      }
    })
  );

  return (
    <>
      {props.img && (
        <div className={`card card-style ${props.className} ${props.height === 'cover' ? ' m-0 rounded-0' : ''}`} style={{ height: `${winHeight}px`, backgroundImage: `url(${props.img})` }}>
          {props.children}
        </div>
      )}
      {!props.img && (
        <div className={`card card-style ${props.className} ${props.height === 'cover' ? ' m-0 rounded-0' : ''}`} style={{ height: `${winHeight}px` }}>
          {props.children}
        </div>
      )}
    </>
  );
};
CardStyle.defaultProps = { className: '', height: '', image: '' };

//Card Style Positioning Divs
export const CardTop = (props) => (
  <div onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} className={`card-top ${props.className}`}>
    {props.children}
  </div>
);
CardTop.defaultProps = { className: '', onMouseOver: () => {}, onMouseLeave: () => {} };

export const CardCenter = (props) => (
  <div onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} className={`card-center ${props.className}`}>
    {props.children}
  </div>
);
CardCenter.defaultProps = { className: '', onMouseOver: () => {}, onMouseLeave: () => {} };

export const CardBottom = (props) => (
  <div onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} className={`card-bottom ${props.className}`}>
    {props.children}
  </div>
);
CardBottom.defaultProps = { className: '', onMouseOver: () => {}, onMouseLeave: () => {} };

export const CardBody = (props) => (
  <div onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} className={`card-body ${props.className}`}>
    {props.children}
  </div>
);
CardBody.defaultProps = { className: '', onMouseOver: () => {}, onMouseLeave: () => {} };

export const CardOverlay = (props) => <div className={`card-overlay ${props.className}`}></div>;
CardOverlay.defaultProps = { className: '' };

export const CardOverlayImage = (props) => <div className={`card-overlay ${props.className}`} style={{ height: `${props.height}`, backgroundImage: `url(${props.img})` }}></div>;
CardOverlayImage.defaultProps = { height: '', img: '', className: '' };

export const CardOverlayInfinite = (props) => <div style={{ backgroundImage: `url(${props.bg})` }} className={`card-overlay-infinite ${props.className}`}></div>;
CardOverlayInfinite.defaultProps = { bg: '', className: '' };

export default CardStyle;
