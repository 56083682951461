import { useMemo, useState } from 'react';

const useOS = (appleGet, androidGet, noneGet) => {
  const [androidCheck, setAndroidCheck] = useState();
  const [iosCheck, setIosCheck] = useState();
  const [noneCheck, setNoneCheck] = useState();

  let checkForMobile = useMemo(() => {
    let isMobile = {
      Android: function () {return navigator.userAgent.match(/Android/i);},
      iOS: function () {return navigator.userAgent.match(/iPhone|iPad|iPod/i);},
      any: function () {return isMobile.Android() || isMobile.iOS();},
    };
    if (!isMobile.any()) {
        setNoneCheck(true)
    }
    if (isMobile.iOS()) {
        setIosCheck(true)
    }
    if (isMobile.Android()) {
        setAndroidCheck(true)
    }
    return [iosCheck, androidCheck, noneCheck]
  }, [androidCheck, iosCheck, noneCheck]);

  return [checkForMobile[0], checkForMobile[1], checkForMobile[2]];
};

export default useOS;
