import React from 'react';

const Divider = (props) => {
    return (
        <div className={`divider ${props.margins ? 'divider-margins' : ''} ${props.className}`}></div>
    );
};

Divider.defaultProps = {
    className:'',
    margins: ''
}

export const DividerIcon = (props) =>{
    return (
        <div className={`divider ${props.margins ? 'divider-margins' : ''} divider-icon  ${props.className}`}><i className={`${props.icon}`}></i></div>
    )
}

export default Divider;