export function checkVisited() {
  let checkVisited = document.querySelectorAll('.check-visited');
  if (checkVisited.length) {
    let pwaName = 'Sticky';
    let visited_links = JSON.parse(localStorage.getItem(pwaName + '_Visited_Links')) || [];
    let links = document.querySelectorAll('.check-visited a');
    for (let i = 0; i < links.length; i++) {
      let allVisitedLinks = links[i];
      allVisitedLinks.addEventListener('click', function (e) {
        let clicked_url = this.href;
        if (visited_links.indexOf(clicked_url) === -1) {
          visited_links.push(clicked_url);
          localStorage.setItem(pwaName + '_Visited_Links', JSON.stringify(visited_links));
        }
      });
      if (visited_links.indexOf(allVisitedLinks.href) !== -1) {
        if (!allVisitedLinks.classList.contains('visited-link')) {
          allVisitedLinks.className += ' visited-link';
        }
      }
    }
  }
}
