import React from 'react';
import { Modal, Offcanvas } from 'react-bootstrap';

const Panel = (props) => {
  if (props.show !== false) {
    document.body.classList.add('add-transitions');
  }
  if (props.show === false) {
    setTimeout(function () {
      document.body.classList.remove('add-transitions');
    }, 350);
  }

  let header = document.querySelectorAll('.header')[0];
  let content = document.querySelectorAll('.page-content')[0];
  let footer = document.querySelectorAll('#footer-bar')[0];

  const moveItemsX = (data) => {
    if(content){content.style.transform = `translateX(${data}px`;}
    if(header){header.style.transform = `translateX(${data}px)`;}
    if(footer){footer.style.transform = `translateX(${data}px)`;}
  };

  const moveItemsY = (data) => {
    if(content){content.style.transform = `translateY(${data}px`;}
    if(header){header.transform = `translateY(${data}px)`;}
    if(footer){footer.style.transform = `translateY(${data}px)`;}
  };

  const restoreItems = () => {
    if(content){content.style.transform = 'translate(0px)';}
    if(header){header.style.transform = 'translate(0px)';}
    if(footer){footer.style.transform = 'translate(0px)';}
  };

  if (props.placement === 'start' && props.show === true) {
    if (props.effect === 'push') {
      moveItemsX(props.size);
    }
    if (props.effect === 'parallax') {
      moveItemsX(props.size / 4);
    }
  }

  if (props.placement === 'end' && props.show === true) {
    if (props.effect === 'push') {
      moveItemsX('-' + props.size);
    }
    if (props.effect === 'parallax') {
      moveItemsX('-' + props.size / 4);
    }
  }

  if (props.placement === 'bottom' && props.show === true) {
    if (props.effect === 'push') {
      moveItemsY('-' + props.size);
    }
    if (props.effect === 'parallax') {
      moveItemsY('-' + props.size / 4);
    }
  }

  if (props.placement === 'top' && props.show === true) {
    if (props.effect === 'push') {
      moveItemsY(props.size);
    }
    if (props.effect === 'parallax') {
      moveItemsY(props.size / 4);
    }
  }

  const restoreItemsHandler = () => {
    restoreItems();
  };

  return (
    <>
      {(props.placement === 'start' || props.placement === 'end') && props.cover && (
        <Offcanvas onExiting={restoreItemsHandler} className={props.className} show={props.show} onHide={props.onHide} placement={props.placement} style={{ width: '100%', height: '100%' }}>
          {props.children}
        </Offcanvas>
      )}
      {(props.placement === 'top' || props.placement === 'bottom') && props.cover && (
        <Offcanvas onExiting={restoreItemsHandler} className={props.className} show={props.show} onHide={props.onHide} placement={props.placement} style={{ width: '100%', height: '100%' }}>
          {props.children}
        </Offcanvas>
      )}
      {(props.placement === 'center' || props.placement === 'modal') && props.cover && (
        <Modal style={{ inset: '0px' }} onExiting={restoreItemsHandler} centered scrollable="true" dialogClassName="m-0" contentClassName={`border-0 bg-theme start-0 end-0 bottom-0 top-0 custom-modal position-absolute ${props.className}`} show={props.show} onHide={props.onHide} placement={props.placement}>
          <Modal.Body className="p-0">{props.children}</Modal.Body>
        </Modal>
      )}

      {(props.placement === 'start' || props.placement === 'end') && !props.cover && (
        <Offcanvas onExiting={restoreItemsHandler} className={props.className} show={props.show} onHide={props.onHide} placement={props.placement} style={{ width: `${props.size}px` }}>
          {props.children}
        </Offcanvas>
      )}
      {(props.placement === 'top' || props.placement === 'bottom') && !props.cover && (
        <Offcanvas onExiting={restoreItemsHandler} className={props.className} show={props.show} onHide={props.onHide} placement={props.placement} style={{ height: `${props.size}px` }}>
          {props.children}
        </Offcanvas>
      )}
      {(props.placement === 'center' || props.placement === 'modal') && !props.cover && (
        <Modal style={{ width: `${props.size}px`, left: `${props.size ? '50%' : ''}`, marginLeft: `-${props.size / 2}px` }} onExiting={restoreItemsHandler} centered scrollable="true" dialogClassName="m-0" contentClassName={`border-0 bg-theme custom-modal ${props.className}`} show={props.show} onHide={props.onHide} placement={props.placement}>
          <Modal.Body className="p-0">{props.children}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

Panel.defaultProps = {
  cover: null,
  size: '',
  show: '',
  effect: '',
  className: '',
  placement: '',
};

export default Panel;
