import React from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import MenuTitle from '../../Comon/MenuTitle';

const BackgroundMenu = (props) => {
  const changeBackground = (e) => {
    let getData = e.target.dataset.background;
    document.body.setAttribute('data-background', getData);
    localStorage.setItem('Sticky-background', getData);
  };
  return (
    <Offcanvas show={props.backgroundSelector} onHide={props.closeMenuHandler} placement="bottom" className="mx-3 mb-3 p-3 rounded-m">
      <MenuTitle title="Backgrounds" text="Change Page Color Behind Content Boxes" closeMenuHandler={props.closeMenuHandler} />

      <div className="divider mb-2 mt-3"></div>
      <div className="background-changer mx-n3">
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="default">
          <i className="bg-theme"></i>
          <span className="color-dark-dark">Default</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="plum">
          <i className="body-plum"></i>
          <span className="color-plum-dark">Plum</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="magenta">
          <i className="body-magenta"></i>
          <span className="color-dark-dark">Magenta</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="dark">
          <i className="body-dark"></i>
          <span className="color-dark-dark">Dark</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="violet">
          <i className="body-violet"></i>
          <span className="color-violet-dark">Violet</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="red">
          <i className="body-red"></i>
          <span className="color-red-dark">Red</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="green">
          <i className="body-green"></i>
          <span className="color-green-dark">Green</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="sky">
          <i className="body-sky"></i>
          <span className="color-sky-dark">Sky</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="orange">
          <i className="body-orange"></i>
          <span className="color-orange-dark">Orange</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={changeBackground} data-background="yellow">
          <i className="body-yellow"></i>
          <span className="color-yellow-dark">Yellow</span>
        </Button>
        <div className="clearfix"></div>
      </div>
      <Button className="btn w-100 btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4" onClick={props.showSettingsMenu}>
        Back to Settings
      </Button>
    </Offcanvas>
  );
};

export default BackgroundMenu;
