import React, { useEffect, useRef, useState } from 'react';
import useOS from '../../Services/Hooks/useOS';
import Panel from '../Panel/Panel';
import pwaIcon from '../../assets/images/IconOnly_Transparent_NoBuffer.png';
let pwaRemind = 1 // set remind time in days
let pwaName = "eSignboard" // name that will be applied to local storage variables


const PWAPanels = () => {
  const [isApple, isAndroid] = useOS(false, false);
  const [pwaApple, setPwaApple] = useState(false);
  const [pwaAndroid, setPwaAndroid] = useState(false);

  const androidRef = useRef(null);

  const pwaWindowHandler = () => {
    setPwaAndroid(false);
    setPwaApple(false);
    var now = Date.now();
    localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
    localStorage.setItem(pwaName + '-PWA-Prompt', 'install-rejected');
    console.log('PWA Install Rejected. Will Show Again in ' + pwaRemind + ' Days');
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      if ('serviceWorker' in navigator) {

        var deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          deferredPrompt = e;
          console.log('PWA Prompt Activated. Checking Local Storage');

          var hours = pwaRemind * 24; // Reset when storage is more than 24hours
          var now = Date.now();
          var setupTime = localStorage.getItem(pwaName + '-PWA-Timeout-Value');
          if (setupTime == null) {
            localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
          } else if (now - setupTime > hours * 60 * 60 * 1000) {
            localStorage.setItem(pwaName + '-PWA-Prompt', 'instal-not-shown');
            localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
          }
          if (localStorage.getItem(pwaName + '-PWA-Prompt') !== 'install-rejected') {
            if (isAndroid === true && !window.matchMedia('(display-mode: fullscreen)').matches) {
              setTimeout(function () {
                setPwaAndroid(true);
                androidRef.current.addEventListener('click', function () {
                  deferredPrompt.prompt();
                  deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                      console.log('Added');
                    } else {
                      console.log('User Rejected Installation');
                    }
                    deferredPrompt = null;
                  });
                });
              }, 2500);
            }
            if (isApple === true && !window.matchMedia('(display-mode: fullscreen)').matches) {
              setTimeout(function () {
                setPwaApple(true);
              }, 2500);
            }
          }

        });
      }
    }
  }, [isApple, isAndroid]);

  return (
    <>
      <Panel show={pwaAndroid} onHide={() => setPwaAndroid(false)} placement="bottom" className="m-3 mx-2 p-3 rounded-m">
        <div className="boxed-text-l mt-3 pb-2">
          <img className="rounded-l mb-3" src={pwaIcon} alt="Android PWA Icon" width="90" />
          <h4 className="mt-3">Add eSignboard on your Home Screen</h4>
          <p>Install eSignboard on your home screen, and access it just like a regular App. It really is that simple!</p>
          <button ref={androidRef} className="btn btn-m rounded-sm shadow-l text-uppercase font-900 bg-highlight mb-2">
            Add to Home Screen
          </button>
          <button onClick={pwaWindowHandler} className="color-gray-dark text-uppercase font-900 opacity-60 font-12 pt-2 d-block text-center w-100">
            Maybe Later
          </button>
        </div>
      </Panel>
      <Panel show={pwaApple} onHide={() => setPwaApple(false)} placement="bottom" className="m-3 mx-2 p-3 rounded-m">
        <div className="boxed-text-l mt-3 pb-2">
          <img className="rounded-l mb-3" src={pwaIcon} alt="Android PWA Icon" width="90" />
          <h4 className="mt-3">Add eSignboard on your Home Screen</h4>
          <p>Install eSignboard, and access it like a regular App. Open your Safari menu and tap <br/>"Add to Home Screen".</p>
          <button onClick={pwaWindowHandler} className="color-highlight text-uppercase font-700">
            Maybe Later
          </button>
        </div>
      </Panel>
    </>
  );
};

export default PWAPanels;
