import React from 'react';

const MenuTitle = (props) => {
  return ( 
    <div className={`d-flex ${props.className}`}>
      <div className="align-self-center me-auto">
        <h1 className={`mb-n2 ${props.titleStyle} ${!props.text && 'mt-n2'}`}>{props.title}</h1>
        <p className={`mb-0 ${props.textStyle} font-11`}>{props.text}</p>
      </div>
      <div className="align-self-center ms-auto">
        <button onClick={props.closeMenuHandler} className="icon icon-m me-n3">
          <i className="fa fa-times color-red-dark font-20"></i>
        </button>
      </div>
    </div>
  );
};

MenuTitle.defaultProps={
    onCloseHandler: ()=>{},
    className:'',
    title:'Menu Title',
    text:null,
    textStyle:'color-highlight',
    titleStyle:''
}

export default MenuTitle;
