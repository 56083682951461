import React from 'react';

const Preloader = () => {
  return (
    <div id="preloader">
      <div className="spinner-border color-highlight" role="status"></div>
    </div>
  );
};

export default Preloader;
