import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { checkVisited } from '../../Services/Helpers/check-visited';

const LinkList = (props) => {
  useEffect(() => {
    checkVisited();
  });
  return <div className={`list-group list-custom-${props.size} ${props.border}-border ${props.className}`}>{props.children}</div>;
};

LinkList.defaultProps = {
  size: '',
  border: '',
  className: '',
};

export const NavItem = (props) => {
  return (
    <>
      <NavLink to={props.to}>
        <i className={props.icon ? props.icon : 'disabled'}></i>
        <span className={props.font}>{props.title}</span>
        <strong>{props.text}</strong>
        {props.badge && <span className={`badge ${props.badgeColor} font-10`}>{props.badge}</span>}
        {props.lastIcon && <i className={props.lastIcon}></i>}
      </NavLink>
    </>
  );
};

NavItem.defaultProps = {
  icon: null,
  font: '',
  title: '',
  text: '',
  lastIcon: 'fa fa-angle-right',
  badge: '',
  badgeColor: 'bg-highlight',
};

export const SwitchItem = (props) => {
  const [isChecked, setIsChecked] = useState(props.checked);
  let id = 'switch-' + Math.random().toFixed(2);
  const inputRef = useRef();
  const onClickHandler = (e) => {
    if (!props.disabled) {
      e.preventDefault();
      if (inputRef.current.checked === false) {
        inputRef.current.checked = true;
      } else {
        inputRef.current.checked = false;
      }
      props.onClick(inputRef.current.checked);
    } else {
      inputRef.current.checked = props.checked
    }
  };

  useEffect(() => {
    if (props.checked === false) {
      setIsChecked(false);
      inputRef.current.checked = false;
    }
    if (props.checked === true) {
      setIsChecked(true);
      inputRef.current.checked = true;
    }
  }, [props.checked]);

  return (
    <>
      <button onClick={onClickHandler} className={`text-start ${props.className}`}>
        <i className={props.icon ? props.icon : 'disabled'}></i>
        <span className={props.font}>{props.title}</span>
        <strong>{props.text}</strong>
        <div className={`custom-control scale-switch ${props.type}-switch`}>
          <input id={id} ref={inputRef} type="checkbox" className={`${props.type}-input`} defaultChecked={isChecked} />
          <label className="custom-control-label" htmlFor={id}></label>
        </div>
        {props.lastIcon && <i className={props.lastIcon}></i>}
      </button>
    </>
  );
};

let randomId = 'input-' + 1 + Math.random().toFixed(2);
SwitchItem.defaultProps = {
  onClick: () => {},
  id: randomId,
  font: '',
  title: '',
  type: 'ios',
  lastIcon: 'fa fa-angle-right',
  className: '',
  disabled: null,
};

export const ButtonItem = (props) => {
  return (
    <>
      <button onClick={props.onClick} className={`text-start ${props.className}`}>
        <i className={props.icon ? props.icon : 'disabled'}></i>
        <span className={props.font}>{props.title}</span>
        <strong>{props.text}</strong>
        {props.badge && <span className={`badge ${props.badgeColor} font-10`}>{props.badge}</span>}
        {props.badge2 && <span className="badge badge-text color-theme text-capitalize font-14 font-400 opacity-60">{props.badge2}</span>}
        {props.lastIcon && <i className={props.lastIcon}></i>}
      </button>
    </>
  );
};

ButtonItem.defaultProps = {
  onClick: () => {},
  icon: null,
  className: '',
  badge: '',
  badge2: null,
  lastIcon: 'fa fa-angle-right',
  badgeColor: 'bg-highlight',
};

export default LinkList;
