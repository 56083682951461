import React, { useState, useEffect } from "react";
import Panel from '../../Components/Panel/Panel';
import Btn from '../../Components/Btn/Btn';
import { t } from 'i18next';

const ESignboardIdlePrompt = (props) => {

    const resetHandler = () => {
        props.reset();
        props.setShowPrompt(false)
    }

    return (
        <Panel show={props.showPrompt} onHide={resetHandler} placement={props.placement} className="m-3 p-3 rounded-m">
            <div className="text-center">
                <div className="mt-5 mb-3">
                    <i className="fa-duotone fa-lightbulb-on fa-6x fa-fade" style={{ color: "#5D9CEC" }}></i>
                </div>
                <h1 className="font-28 font-300 color-blue-light mb-3">{t('still.with.us')}</h1>
                <Btn shadowBg="s" full caps size="l" bg="blue-light" onClick={resetHandler}>
                    {t('with.us.yet')}
                </Btn>
                <div className="mb-3">
                    {t('secure.logoff')}
                </div>
            </div>
        </Panel>
    );
}

export default ESignboardIdlePrompt;