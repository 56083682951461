import React from 'react';
import { NavLink } from 'react-router-dom';

const Btn = (props) => {
  return (
    <>
      {props.as === 'button' && (
        <button onClick={props.onClick} className={`btn btn-${props.size} ${props.caps ? 'text-uppercase' : ''} ${props.icon ? 'btn-icon text-start' : ''} bg-${props.bg}  rounded-${props.round} ${props.full ? 'w-100' : ''} color-${props.color} ${props.gradient ? 'gradient-' + props.gradient : ''} ${props.border ? 'border border-' + props.border : ''} font-${props.bold} ${props.shadowBg ? `shadow-bg shadow-bg-${props.shadowBg}` : ''} ${props.shadow ? `shadow-${props.shadow}` : ''} ${props.className}`}>
          {props.children || props.text}
        </button>
      )}
      {props.as === 'link' && (
        <a href={props.href} onClick={props.onClick} className={`btn btn-${props.size} ${props.caps ? 'text-uppercase' : ''} ${props.icon ? 'btn-icon text-start' : ''} bg-${props.bg} rounded-${props.round}  ${props.full ? 'w-100' : ''} color-${props.color}  ${props.gradient ? 'gradient-' + props.gradient : ''} ${props.border ? 'border border-' + props.border : ''} font-${props.bold} ${props.shadowBg ? `shadow-bg shadow-bg-${props.shadowBg}` : ''} ${props.shadow ? `shadow-${props.shadow}` : ''} ${props.className}`} target={props.target} rel={props.rel} >
          {props.children || props.text}
        </a>
      )}
      {props.as === 'route' && (
        <NavLink to={props.href} onClick={props.onClick} className={`btn btn-${props.size} ${props.caps ? 'text-uppercase' : ''} ${props.icon ? 'btn-icon text-start' : ''} bg-${props.bg}  rounded-${props.round} ${props.full ? 'w-100' : ''} color-${props.color}  ${props.gradient ? 'gradient-' + props.gradient : ''} ${props.border ? 'border border-' + props.border : ''} font-${props.bold} ${props.shadowBg ? `shadow-bg shadow-bg-${props.shadowBg}` : ''} ${props.shadow ? `shadow-${props.shadow}` : ''} ${props.className}`}>
          {props.children || props.text}
        </NavLink>
      )}
      {props.as === 'a' && (
        <a href={props.href} onClick={props.onClick} className={`${props.className}`} target={props.target} rel={props.rel}>
          {props.children || props.text}
        </a>
      )}
    </>
  );
};

Btn.defaultProps = {
  onClick: () => {},
  as: 'button',
  href: '#',
  round: 'sm',
  size: 'm',
  icon: '',
  caps: '',
  bold: '700',
  bg: 'highlight',
  color: 'white',
  gradient: '',
  border: '',
  shadowBg:'',
  shadow:'',
  className: '',
  full: '',
  text:'',
  rel:'',
  target:''
};

export default Btn;
