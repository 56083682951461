import { configureStore } from "@reduxjs/toolkit";
import settingsSlice from "../Reducers/setting-slice";
import darkSlice from "../Reducers/dark-slice";

const store = configureStore({
  reducer: {
    menuReducer: settingsSlice,
    darkReducer:darkSlice,
  }
});

//Export the reducer actions that will target the store
export default store;
