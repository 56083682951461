import React, { useEffect } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import MenuTitle from '../../Comon/MenuTitle';

const HighlightMenu = (props) => {
  let localHighlight = localStorage.getItem('Sticky-Highlight')
  const changeHighlight = (e) => {
    document.body.setAttribute('data-highlight', e);
    localStorage.setItem('Sticky-Highlight', e);
  };
  useEffect(()=>{
    if(localHighlight){
      document.body.setAttribute('data-highlight', localStorage.getItem('Sticky-Highlight', localHighlight));
    }
  },[localHighlight]);

  return (
    <Offcanvas show={props.highlightSelector} onHide={props.closeMenuHandler} placement="bottom" className="mx-3 mb-3 p-3 rounded-m">
      <MenuTitle title="Highlights" text="Any Element can have a Highlight Color" closeMenuHandler={props.closeMenuHandler} />
      <div className="divider mb-2 mt-3"></div>
      <div className="highlight-changer mx-n3">
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('blue')}}>
          <i className="fa fa-circle color-blue-dark"></i>
          <span className="color-blue-light">Default</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('red')}}>
          <i className="fa fa-circle color-red-dark"></i>
          <span className="color-red-light">Red</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('orange')}}>
          <i className="fa fa-circle color-orange-dark"></i>
          <span className="color-orange-light">Orange</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('pink')}}>
          <i className="fa fa-circle color-pink2-dark"></i>
          <span className="color-pink-dark">Pink</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('magenta')}}>
          <i className="fa fa-circle color-magenta-dark"></i>
          <span className="color-magenta-light">Purple</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('aqua')}}>
          <i className="fa fa-circle color-aqua-dark"></i>
          <span className="color-aqua-light">Aqua</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('teal')}}>
          <i className="fa fa-circle color-teal-dark"></i>
          <span className="color-teal-light">Teal</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('mint')}}>
          <i className="fa fa-circle color-mint-dark"></i>
          <span className="color-mint-light">Mint</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('green')}}>
          <i className="fa fa-circle color-green-light"></i>
          <span className="color-green-light">Green</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('grass')}}>
          <i className="fa fa-circle color-green-dark"></i>
          <span className="color-green-dark">Grass</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('sunny')}}>
          <i className="fa fa-circle color-yellow-light"></i>
          <span className="color-yellow-light">Sunny</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('yellow')}}>
          <i className="fa fa-circle color-yellow-dark"></i>
          <span className="color-yellow-light">Goldish</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('brown')}}>
          <i className="fa fa-circle color-brown-dark"></i>
          <span className="color-brown-light">Wood</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('night')}}>
          <i className="fa fa-circle color-dark-dark"></i>
          <span className="color-dark-light">Night</span>
        </Button>
        <Button bsPrefix=" " variant=" " action="true" onClick={()=>{changeHighlight('dark')}}>
          <i className="fa fa-circle color-dark-light"></i>
          <span className="color-dark-light">Dark</span>
        </Button>
      </div>
      <Button className="btn w-100 btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4" onClick={props.showSettingsMenu}>
        Back to Settings
      </Button>
    </Offcanvas>
  );
};

export default HighlightMenu;
